import { apiSlice } from 'features/api/apiSlice';
import { SignupResponse } from 'features/signup/types/signUpResponse';
import { AutolimitiHappybetResponseApi } from 'sites/happybet/lib/api/autolimitiHappybetResponseApi';
import { AutoPrelievoResponseApi } from 'sites/happybet/lib/api/autoprelievoResponseApi';
import { DetailMovimentiResponseApi, DetailedBetResponseApi } from 'sites/happybet/lib/api/detailedBetResponseApi';
import { MovimentiResponseApi } from 'sites/happybet/lib/api/movimentiResponseApi';
import { PoaTypesResponseApi } from 'sites/happybet/lib/api/poaTypesResponseApi';
import { ProfileContractApiHappybet } from 'sites/happybet/lib/api/profileContractApiHappybet';
import { ReasonAutoExclusionResponseApi } from 'sites/happybet/lib/api/reasonsAutoExclusionResponseApi';
import { UrlJumioPoaResponseApi } from 'sites/happybet/lib/api/urlJumioPoaResponseApi';
import { UsersHbetGetNewPasswordReqDto, UsersHbetToWCardsResDto, UsersHbetToWExecReqDto } from 'types/swagger';
import { SignupState } from '../signup/signup';
import { SignupRequest } from '../signup/types';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    autoexclude24H: builder.mutation<void, void>({
      query: () => ({
        url: `users/hbet/user/profile/lock24`,
        method: 'POST',
        body: {},
      }),
    }),
    getAutolimiti: builder.query<AutolimitiHappybetResponseApi, null | void>({
      query: () => '/users/hbet/user/profile/limits',
      providesTags: ['Autolimiti_Happybet'],
    }),
    editAutoLimit: builder.mutation<any, string>({
      query: (limit_request) => ({
        url: `/users/hbet/user/profile/limit`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: limit_request,
      }),
      invalidatesTags: ['Autolimiti_Happybet'],
    }),
    editLugasAutoLimit: builder.mutation<any, string>({
      query: (limit_request) => ({
        url: `/users/hbet/user/profile/limit/lugas?amount=${limit_request}`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        //body: limit_request,
        // body: {
        //   ...limit_request,
        // },
      }),
      // invalidatesTags: ['AutolimitiLugasMutation_Happybet'],
    }),
    //  /users/hbet/movimenti?pageNumber=1&pageSize=100
    signUpHappybet: builder.query<SignupResponse, SignupState>({
      query: (data) => {
        const body: SignupRequest = {
          idSessione: crypto.randomUUID(),
          btag: data.btag ?? '',
          nome: data.name,
          cognome: data.surname,
          cognomeNascita: data.geburtsname, // cognome da nubile
          dataNascita: new Date(data.birthDate),
          comuneNascita: data.cityOfBirth,
          indirizzoResidenza: data.address,
          comuneResidenza: data.city,
          capResidenza: data.cap,
          sesso: Number(data.gender),
          email: data.email,
          telefonoMobile: data.telephone,
          flagMarketing: data.marketingUsage ? 1 : 0,
          flagProfilazione: data.profilingUsage ? 1 : 0,
          flagTerminiCondizioniContratto: data.acceptTermAndCondition ? 1 : 0,
          codicePromo: data.promoCode,
          username: data.username,
          password: data.password,
          idOtpTelefono: data.userSessionTelephone! ?? '',
          tipoState: data.state, // es. "HH"
          nazionalita: data.nationality, // es. "AR"
          tipoCountry: data.province, // es. "DE"
          idOtpEmail: '',
          numeroDoc: '',
          capDomicilio: '',
          cf: '',
          codiceTipoAutorita: '',
          comuneDomicilio: '',
          indirizzoDomicilio: '',
          luogoRilascioDoc: '',
          provinciaDomicilio: '',
          provinciaNascita: '',
          provinciaResidenza: '',
          rispostaDomandaSegreta: '',
        };
        return {
          method: 'POST',
          url: `users/hbet/user/profile/contract/new`,
          body: JSON.stringify(body),
        };
      },
    }),
    getMovimentiHappybet: builder.query<
      MovimentiResponseApi,
      {
        pageNumber?: number;
        pageSize?: number;
        dataDa: string;
        dataA: string;
        stato: string;
        direzione: string;
        tipologia: string;
      }
    >({
      query: ({ pageNumber, pageSize, dataDa, dataA, stato, direzione, tipologia }) => ({
        url: `movimenti/hbet/movimenti${
          pageNumber && pageSize ? `?pageNumber=${pageNumber}&pageSize=${pageSize}` : ``
        }`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: {
          dataDa,
          dataA,
          stato,
          direzione,
          tipologia,
        },
      }),
    }),
    getDetailedMovimentiHappybet: builder.query<
      DetailedBetResponseApi | DetailMovimentiResponseApi,
      { idBiglietto: string; idBigl: string; codTipoGioco: string }
    >({
      query: ({ idBiglietto, idBigl, codTipoGioco }) => ({
        url: `movimenti/hbet/movimenti/details`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: {
          carta: '', // obbligatorio, ma lasciare vuoto
          id_biglietto: idBiglietto,
          id_bigl: '', //idBigl,
          cod_tipo_gioco: codTipoGioco,
        },
      }),
    }),
    getProfileContractHappybet: builder.query<ProfileContractApiHappybet, null | void>({
      query: () => `users/hbet/user/profile/contract`,
      providesTags: ['ProfileContract'],
    }),
    editContractProfileHappybet: builder.mutation<any, {}>({
      query: (profileContractRequest) => ({
        url: `users/hbet/user/profile/contract/edit`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: {
          ...profileContractRequest,
        },
      }),
      invalidatesTags: ['ProfileContract'],
    }),
    getAutoPrelievoHappybet: builder.query<AutoPrelievoResponseApi, null | void>({
      query: () => `users/hbet/user/wallet/withdrawal/auto`,
      providesTags: ['AutoPrelievo_Happybet'],
    }),
    postAutoPrelievoHappybet: builder.mutation<any, string>({
      query: (data) => ({
        url: `users/hbet/user/wallet/withdrawal/auto?importo=${data}`,
        method: 'POST',
      }),
      invalidatesTags: ['AutoPrelievo_Happybet'],
    }),
    checkUsernameHappybet: builder.query<{ success: boolean }, { username: string }>({
      query: ({ username }) => `users/hbet/user/profile/username?username=${username}`,
    }),
    checkEmailHappybet: builder.query<{ success: boolean }, { email: string }>({
      query: ({ email }) => `users/hbet/user/profile/email?email=${email}`,
    }),
    getReasonsAutoExclusionHappybet: builder.query<ReasonAutoExclusionResponseApi[], null | void>({
      query: () => `users/hbet/user/profile/lock`,
    }),
    postAutoExclusionHappybet: builder.mutation<void, { tipo: string; periodo: string; cause: string[] }>({
      query: ({ tipo, periodo, cause }) => ({
        url: `users/hbet/user/profile/lock`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: {
          tipo,
          periodo,
          cause,
        },
      }),
      transformErrorResponse: (response: any) => {
        console.log(response, 'responseAny');
        return {
          status: response.status,
          data: response.data,
        };
      },
    }),
    getPoaTypes: builder.query<PoaTypesResponseApi, null | void>({
      query: () => `users/hbet/user/profile/contract/poa/types`,
    }),
    getUrlJumioPoa: builder.query<UrlJumioPoaResponseApi, string>({
      query: (type) => `users/hbet/user/profile/contract/poa/validate?type=${type}`,
    }),
    newDigitalCard: builder.mutation<
      void,
      {
        codiceContratto: string;
        provenienza?: string;
        btag?: string;
        username: string;
        password: string;
      }
    >({
      query: ({ codiceContratto, provenienza, btag, username, password }) => ({
        url: `users/hbet/user/profile/contract/new-card`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: {
          codiceContratto,
          provenienza,
          btag,
          username,
          password,
        },
      }),
    }),
    editPasswordHappybet: builder.mutation<any, { username?: string | null; password: string }>({
      query: ({ username, password }) => ({
        url: `users/hbet/user/profile/password`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: {
          carta: '',
          username,
          password,
        },
      }),
    }),
    checkLogin: builder.mutation<any, { type: string; isLogged: boolean }>({
      query: ({ type, isLogged }) => ({
        url: `users/hbet/user/profile/check-login`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: {
          type,
          isLogged,
        },
      }),
    }),
    changeArea: builder.mutation<any, { destinationArea: string }>({
      query: ({ destinationArea }) => ({
        url: `users/hbet/user/changearea`,
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: {
          destinationArea,
        },
      }),
    }),
    resetPasswordHappybet: builder.query<void, UsersHbetGetNewPasswordReqDto>({
      query: (data) => {
        return {
          url: `users/hbet/user/profile/credential`,
          method: 'POST',
          body: JSON.stringify(data),
        };
      },
    }),
    getUserWalletWithdrawalTowList: builder.query<UsersHbetToWCardsResDto, void>({
      query: () => 'users/hbet/user/wallet/withdrawal/tow',
    }),
    postUserWalletWithdrawalTowCreate: builder.mutation<
      void,
      { cartaOperazione: string; codiceCategoriaDeposito?: number | null; importo: number }
    >({
      query: ({ cartaOperazione, codiceCategoriaDeposito, importo }) => {
        return {
          url: 'users/hbet/user/wallet/withdrawal/tow',
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: {
            cartaOperazione,
            codiceCategoriaDeposito,
            importo,
          },
        };
      },
    }),
    getUserWalletPaymentTowList: builder.query<UsersHbetToWCardsResDto, void>({
      query: () => 'users/hbet/user/wallet/payment/tow',
    }),
    postUserWalletPaymentTowCreate: builder.mutation<
      void,
      { cartaOperazione: string; codiceCategoriaDeposito?: number | null; importo: number }
    >({
      query: ({ cartaOperazione, codiceCategoriaDeposito, importo }) => {
        return {
          url: 'users/hbet/user/wallet/payment/tow',
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: {
            cartaOperazione,
            codiceCategoriaDeposito,
            importo,
          },
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  usePostUserWalletPaymentTowCreateMutation,
  useGetUserWalletPaymentTowListQuery,
  usePostUserWalletWithdrawalTowCreateMutation,
  useGetUserWalletWithdrawalTowListQuery,
  useAutoexclude24HMutation,
  useGetAutolimitiQuery,
  useEditAutoLimitMutation,
  useEditLugasAutoLimitMutation,
  useLazySignUpHappybetQuery,
  useGetMovimentiHappybetQuery,
  useGetDetailedMovimentiHappybetQuery,
  useGetProfileContractHappybetQuery,
  useGetAutoPrelievoHappybetQuery,
  usePostAutoPrelievoHappybetMutation,
  useLazyCheckUsernameHappybetQuery,
  useLazyCheckEmailHappybetQuery,
  useGetReasonsAutoExclusionHappybetQuery,
  usePostAutoExclusionHappybetMutation,
  useEditContractProfileHappybetMutation,
  useGetPoaTypesQuery,
  useLazyGetUrlJumioPoaQuery,
  useGetUrlJumioPoaQuery,
  useNewDigitalCardMutation,
  useEditPasswordHappybetMutation,
  useCheckLoginMutation,
  useChangeAreaMutation,
  useLazyResetPasswordHappybetQuery,
} = extendedApi;
